<template>
    <v-card flat class="pa-3 mt-2">
        <v-card-subtitle>
           Forma pago
        </v-card-subtitle>
        <v-form ref="form" lazy-validation>
            <v-card-text class="d-flex">
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" lg="12">
                                <v-text-field :rules="reglas.nombre" v-model="datos.descripcion"
                                    label="Descripción *" required outlined dense hide-details="auto"
                                    class="">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <SelectFormaPagoGeneral ref="SelectFormaPagoGeneralRef" :clearable="true" :dense="true"
                                    :outlined="true" :icon="false" :rules="reglas.formaPagoGeneral" v-model="datos.idFormaPagoGeneral" class="mb-6 mt-5">
                                </SelectFormaPagoGeneral>
                                <SelectMoneda ref="SelectMonedaRef" :clearable="true" :dense="true"
                                    :outlined="true" :icon="false" :rules="reglas.moneda" v-model="datos.idMoneda" class="mb-6 mt-5">
                                </SelectMoneda>
                                <v-text-field :rules="reglas.maximoMontoCambio" v-model="datos.maximoMontoCambio"
                                    label="Monto máximo cambio *" required outlined dense hide-details="auto"
                                    class="mb-6 mt-5">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <v-text-field :rules="reglas.minimoMontoVoucher" v-model="datos.minimoMontoVoucher"
                                    label="Monto mínimo voucher *" required outlined dense hide-details="auto"
                                    class="mb-6 mt-5">
                                </v-text-field>
                                <v-text-field :rules="reglas.montoMinimo" v-model="datos.montoMinimo"
                                    label="Monto mínimo *" required outlined dense hide-details="auto"
                                    class="mb-6 mt-5">
                                </v-text-field>
                                <v-text-field :rules="reglas.montoMaximo" v-model="datos.montoMaximo"
                                    label="Monto máximo *" required outlined dense hide-details="auto"
                                    class="mb-6 mt-5">
                                </v-text-field>
                            </v-col>
                        </v-row>    
                    </v-col>
                    <v-switch class="ml-4 mr-5" v-model="datos.indSolicitarBanco" :label="`Ind Solicitar banco`"></v-switch>
                    <v-switch v-model="datos.indReconversion" :label="`Ind Reconverción`"></v-switch>
                    <v-col cols="12" class="text-right">
                        <v-btn color="secondary" class="me-3  " :disabled="guardando" @click="limpiar()" small> Limpiar
                        </v-btn>
                        <v-btn color="primary" class="me-3" :loading="guardando" small @click="Guardar()"> Guardar </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-form>
    </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import SelectMoneda from '../../../producto/maestro/moneda/componentes/Select.vue'
import SelectFormaPagoGeneral from './SelectFormaPagoGeneral.vue'
import FormaPagoServices from '@/api/servicios/FormaPagoServices'
export default {
    components: {
        SelectMoneda,
        SelectFormaPagoGeneral
    },
    setup(props, context) {
        const guardando = ref(false);
        const form = ref(null);
        const SelectMonedaRef = ref(null);
        const SelectFormaPagoGeneralRef = ref(null);
        const reglas = {
            nombre: [

                v => !!v || "Descripcion es requerido"
            ],
            formaPagoGeneral: [

                v => !!v || "Forma pago general es requerido"
            ],
            moneda: [

            v => !!v || "Moneda es requerido"
            ],
            maximoMontoCambio: [

                v => !!v || "Monto máximo cambio  es requerido"
            ],
            minimoMontoVoucher: [

                v => !!v || "Monto mínimo voucher es requerido"
            ],
            montoMinimo: [

                v => !!v || "Monto mínimo cambio es requerido"
            ],
            montoMaximo: [

                v => !!v || "Monto máximo cambio es requerido"
            ],

        }
        const datosInit = {
            "id": -1,
            "idSucursal": -1,
            "descripcion": "",
            "idFormaPagoGeneral": -1,
            "idMoneda": -1,
            "maximoMontoCambio": null,
            "minimoMontoVoucher": null,
            "montoMinimo": null,
            "montoMaximo": null,
            "indReconversion": false,
            "indActivo": true,
            "factorReconversion": null,
            "indSolicitarBanco": false,
        }
        const datos = ref(JSON.parse(JSON.stringify(datosInit)));
        const limpiar = () => {
            datos.value = JSON.parse(JSON.stringify(datosInit));
            form.value.resetValidation();
        }
        const cargandos = ref(false)
        const idMoneda = (item) => {
            datos.value.idMoneda = item.id 
        }
        const CargarEditar = (item) => {
            datos.value = item
            SelectMonedaRef.value.selectId(item.idMoneda)
            SelectFormaPagoGeneralRef.value.selectId(item.idFormaPagoGeneral)  
            goTo(0)
        }

        const validarForm = () => {
            let val = form.value?.validate()

            return val
        }
        const ActualizarLista = item => {
            console.log(item)
            context.emit('GetActualizarLista', { ...item })

        }
        const Guardar = () => {
            guardando.value = true
            if (validarForm()) {
                try { 
                    FormaPagoServices.Actualizar(
                        store.state.user.idUsuario,
                        datos.value.id,
                        datos.value.idSucursal = datos.value.id == -1 ? store.state.sucursalSelect.id : datos.value.idSucursal,
                        datos.value.idMoneda,
                        datos.value.idFormaPagoGeneral,
                        datos.value.descripcion,
                        datos.value.maximoMontoCambio,
                        datos.value.minimoMontoVoucher,
                        datos.value.montoMaximo,
                        datos.value.montoMinimo,
                        datos.value.indReconversion,
                        datos.value.indActivo,
                        datos.value.factorReconversion ,
                        datos.value.indSolicitarBanco,
                    ).then(response => {
                        console.log(response)
                        if (response.data.estatus == true) {
                            store.commit('setAlert', {
                                message: response.data.mensaje,
                                type: 'success',
                            })
                            ActualizarLista(response.data.datos.datos)
                            limpiar()
                        } else {

                            store.commit('setAlert', {
                                message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                                type: 'warning',
                            })
                        }
                    })
                        .catch(error => {
                            console.log(error)
                            store.commit('setAlert', {
                                message: error,
                                type: 'error',
                            })
                        })
                        .finally(() => {
                            guardando.value = false
                        })
                } catch (error) {
                    //alert(error)
                    store.commit('setAlert', {
                        message: error,
                        type: 'error',
                    })
                    guardando.value = false;
                }
            } else {
                store.commit('setAlert', {
                    message: 'Verifique que todos los datos estén completos',
                    type: 'warning',
                })
            }

        }
        return{
            guardando,
            form,
            SelectMonedaRef,
            SelectFormaPagoGeneralRef,
            reglas,
            datos,
            cargandos,
            validarForm,
            CargarEditar,
            ActualizarLista,
            idMoneda,
            limpiar,
            Guardar
        }
    }
}
</script>