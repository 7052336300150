import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class FormaPagoGeneralServices {
    Consultar( 
        datos = {
            Id: -1,
            Descripcion: '',
            indActivo:null
        }
     ) {
        return http.get("formapagogeneral/consultar", { params: datos })
        .catch((error) => {
            if (error.response.status == 401) {
                store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                router.push("/pages/login");
            }
        });
    }
}

export default new FormaPagoGeneralServices();