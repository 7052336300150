<template>
    <div>
        <v-card>
            <v-card-subtitle>Lista Forma de pago
                <v-btn icon @click="cargar()" color="green" :loading="cargandoDatos" :disabled="cargandoDatos">
                    <v-icon>{{ icons.mdiCached }}</v-icon>
                </v-btn>
            </v-card-subtitle>
            <v-card-text>
                <v-row>
                    <v-col cols="12" offset-md="8" md="4">
                        <v-text-field v-model="search" :append-icon="icons.mdiMagnify" label="Buscar" single-line
                            hide-details dense outlined></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
             <!-- Table -->
             <v-data-table :headers="headers" :items="Lista" :search="search" :items-per-page="5" :loading="cargandoDatos"
                class="table-kitchen-sink">
                <template #[`item.moneda`]="{ item }">
                    <div class="d-flex align-center">
                       {{ item.moneda.nombre }}
                    </div>
                </template>
                <template #[`item.simbolo`]="{ item }">
                    <div class="d-flex align-center">
                       {{ item.moneda.simbolo }}
                    </div>
                </template>
                <template #[`item.indActivo`]="{ item }">
                    <div class="d-flex align-center">
                        <v-icon  color="success" v-if="item.indActivo == true" >{{icons.mdiCheck}}</v-icon>
                        <v-icon  color="success" v-else >{{icons.mdiCancel}}</v-icon>
                    </div>
                </template>
                <template #[`item.accion`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <a class="pr-2" v-bind="attrs" v-on="on" @click="GetEditar(item)">
                                <v-icon >{{ icons.mdiPencil }}</v-icon>
                            </a>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="pr-2" icon v-bind="attrs" v-on="on" :loading="item.cargando" @click="CambiarIndActivo(item)">
                                <v-icon>{{ item.indActivo  ? icons.mdiCancel : icons.mdiCheck }} </v-icon>
                            </v-btn>
                        </template>
                        <span>{{ item.indActivo  ? 'Inactivar' : 'Activar' }}</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import { onBeforeMount, ref, context, watch } from '@vue/composition-api'
import {
    mdiMagnify,
    mdiDeleteOutline,
    mdiStoreEdit,
    mdiPencilOutline,
    mdiAccountCancel,
    mdiAccountEdit,
    mdiAccountCheck,
    mdiCheck,
    mdiCancel,
    mdiStoreEditOutline,
    mdiPencil,
    mdiCached
} from '@mdi/js'
import config from '@/api/config'  
import store from '@/store'
import FormaPagoServices from '@/api/servicios/FormaPagoServices'
export default {
    watch: {
        '$store.state.sucursalSelect.id': function(news, old){  
            if(news != old){
                this.cargar();
            }
        }
    },
    setup(props, context) {
        const search = ref('')
        const Lista = ref([])
        const ListaLoad = ref(false)
        const cargandoDatos = ref(false)
        const GetEditar = item => {
            console.log(item)
            context.emit('GetEditar', { ...item })
            
        }
        onBeforeMount(() => {
            cargar()
        });

        const cargar = () => {
            try {

                FormaPagoServices.Consultar({
                    Id: -1,
                    Descripcion: '',
                    idSucursal: store.state.sucursalSelect.id,
                }).then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        Lista.value = response.data.datos
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    cargandoDatos.value = false
                }) 
            } catch (error) {
                //alert(error)
                cargandoDatos.value = false
            }
        }
        const CambiarIndActivo = (item) => {
            try {
                item.cargando = true
                FormaPagoServices.Actualizar(
                    store.state.user.idUsuario,
                    item.id,
                    item.idSucursal,
                    item.idMoneda,
                    item.idFormaPagoGeneral,
                    item.descripcion,
                    item.maximoMontoCambio,
                    item.minimoMontoVoucher,
                    item.montoMaximo,
                    item.montoMinimo,
                    item.indReconversion ,
                    !item.indActivo,
                    item.factorReconversion ,
                    item.indSolicitarBanco,
                ).then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        store.commit('setAlert', {
                            message: response.data.mensaje,
                            type: 'success',
                        })
                        item.cargando = false
                        item.indActivo = !item.indActivo;
                    } else {
                        store.commit('setAlert', {
                            message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                            type: 'warning',
                        });
                        item.cargando = false
                    }
                })
                    .catch(error => {
                        console.log(error)
                        store.commit('setAlert', {
                            message: error,
                            type: 'error',
                        })
                        item.cargando = false
                    })
                    .finally(() => {
                        item.cargando = false
                    })
            } catch (error) {
                //alert(error)
                store.commit('setAlert', {
                    message: error,
                    type: 'error',
                })
            }
        }
        return {
            icons: {
                mdiMagnify,
                mdiDeleteOutline,
                mdiPencilOutline,
                mdiAccountCancel,
                mdiAccountEdit,
                mdiAccountCheck,
                mdiCheck,
                mdiCancel,
                mdiStoreEdit,
                mdiStoreEditOutline,
                mdiPencil,
                mdiCached
            },
            search,
            headers: [
                { text: 'Id', value: 'id' },
                { text: 'Descripcion', value: 'descripcion' }, 
                { text: 'Moneda', value: 'moneda' },
                { text: 'Simbolo', value: 'simbolo' },
                { text: 'Monto máximo cambio', value: 'maximoMontoCambio' },
                { text: 'Monto mínimo voucher', value: 'minimoMontoVoucher' },
                { text: 'Monto mínimo', value: 'montoMinimo' },
                { text: 'Monto máximo', value: 'montoMaximo' }, 
                { text: 'Activo', value: 'indActivo' },  
                { text: 'Accion', value: 'accion', sortable: false },
            ],
            Lista,
            ListaLoad,
            GetEditar,
            cargar,
            config,
            cargandoDatos,
            CambiarIndActivo,
        }
    }
}
</script>