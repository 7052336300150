//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class FormaPagoServices {

    ArqueoCajaConsultar( datos ) {
        return http.get("formapago/arqueoCaja/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
     Consultar( datos ) {
        return http.get("formapago/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }

    Actualizar(
        idUsuario,
        id,
        idSucursal,
        idMoneda,
        idFormaPagoGeneral,
        descripcion,
        maximoMontoCambio,
        minimoMontoVoucher,
        montoMaximo,
        montoMinimo,
        indReconversion,
        indActivo,
        factorReconversion,
        indSolicitarBanco,
    ) {
        const datos = {
            IdUsuario: idUsuario,
            Data:{
                id: id,
                idSucursal: idSucursal,
                idMoneda: idMoneda,
                idFormaPagoGeneral: idFormaPagoGeneral,
                descripcion:descripcion,
                maximoMontoCambio: maximoMontoCambio,
                minimoMontoVoucher: minimoMontoVoucher,
                montoMaximo: montoMaximo,
                montoMinimo: montoMinimo,
                indReconversion: indReconversion,
                indActivo: indActivo,
                factorReconversion: factorReconversion,
                indSolicitarBanco: indSolicitarBanco,
            }
        }
        console.log(datos)
        return http.post("/formapago/actualizar", datos)
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
 
}


export default new FormaPagoServices();