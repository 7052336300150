<template>
    <div>
        <v-autocomplete :prepend-icon="icon ? icons.mdiAlphaCCircle : null" :clearable="clearable" hide-details="auto"
            :disabled="disabled" v-model="select" :items="lista" label="Forma Pago General" item-value="id" item-text="descripcion"
            :dense="outlined" :outlined="outlined" :loading="cargando"
             :rules="rules">
             <template slot="item" slot-scope="data">
                 {{ data.item.descripcion }} 
            </template>
        </v-autocomplete>
    </div>
</template>
<script>
import FormaPagoGeneralServices from '@/api/servicios/FormaPagoGeneralServices';
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'

export default {
    props:{
        value: String,
        jsonRespueta: Boolean,
        disabled: Boolean,
        dense: Boolean,
        outlined: Boolean,
        icon: Boolean,
        todos: Boolean,
        default: Number,
        clearable: Boolean,
        rules: Array,
    },
    setup(props, contex){
        const lista = ref([])
        const select = ref(null)
        const cargando = ref(false)

        watch(props, () => {
            select.value = props.value

        })
        onBeforeMount(() => {
            cargarDatos()
            if (props.default != undefined) {
                select.value = props.default
            }
        })

        const selectId = (id) => {
            select.value = id
        }

        const cargarDatos = () => {
            lista.value = []
            cargando.value = true
            try {

                FormaPagoGeneralServices.Consultar().then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        lista.value = response.data.datos
                    }
                })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        cargando.value = false
                    })
            } catch (error) {

                cargando.value = false
            }
        }

        watch(select, () => {
            if (props.jsonRespueta == true) {
                const valor = lista.value.find(item => (item.id == select.value))
                contex.emit('input',
                    valor == undefined ? null : valor
                )
            } else {
                contex.emit('input',
                    select.value
                )
            }

            
        })
        return {
            icons: {
                mdiAlphaCCircle,
                mdiPlusCircleOutline
            },
            lista,
            select,
            cargando,
            selectId,
        }
    }

        
}
</script>